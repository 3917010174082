// see tailwind.config.js
$obs-primary: #5d5c64;
$obs-secondary: #dc1e35;
$obs-maroon: 'rgb(156, 30, 54)';
$obs-light-orange: 'rgb(255, 156, 1)';
$obs-white: #ffffff;
$obs-success: #008800;
$obs-warning: $obs-light-orange;
$obs-danger: #dc1e35;
$obs-dark: 'rgb(27, 39, 56)';

:root {
  --obs-primary: #{$obs-primary};
  --obs-secondary: #{$obs-secondary};
  --obs-maroon: #{$obs-maroon};
  --obs-light-orange: #{$obs-light-orange};
  --obs-white: #{$obs-white};
  --obs-success: #{$obs-success};
  --obs-warning: var(--obs-light-orange);
  --obs-danger: #{$obs-danger};
  --obs-dark: #{$obs-dark};

  --font: "Open Sans" !important;
  --sidenav-background: var(--obs-primary) !important;
  --sidenav-color: var(--obs-white) !important;
  --sidenav-toolbar-background: var(--obs-primary) !important;
  --sidenav-item-color: var(--obs-white) !important;
  --sidenav-toolbar-background: var(--obs-primary) !important;
  --sidenav-item-icon-color: var(--obs-white) !important;
  --sidenav-item-icon-color-hover: var(--obs-secondary);
  --sidenav-item-icon-color-active: var(--obs-secondary) !important;
  --toolbar-icon-color: var(--obs-primary) !important;

  --sidenav-subheading-color: var(--obs-primary);

  --sidenav-item-background-hover: var(--obs-dark);
  --sidenav-item-background-active: var(--obs-dark);

  // _style-default.scss override
  // Generic
  --color-primary: var(--obs-primary);
  --color-primary-rgb: var(--obs-primary);
  --color-primary-contrast: var(--obs-white);
  --color-primary-contrast-rgb: var(--obs-white);

  --color-accent: var(--obs-secondary);
  --color-accent-rgb: var(--obs-secondary);
  --color-accent-contrast: var(--obs-white);
  --color-accent-contrast-rgb: var(--obs-white);

  --background-base: #f8f9fa;
  --sidenav-width: 280px;
}

