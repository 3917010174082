@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";

// Vex Core (tailwind + material)
@import "./@vex/styles/core";

// Angular Material
// @import "@angular/material/prebuilt-themes/indigo-pink.css";

// Font
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;0,800;1,400;1,600&display=swap";
@import "~@fortawesome/fontawesome-free/css/all.min.css";

// OBS
@import "./assets/theme.scss";


@tailwind components;
@layer components {
  .component-container {
    padding: 26px 50px;
    margin: 0px !important;
    width: 100%;
  }

  .fa-spinner.fa-spin {
    @apply animate-spin;
  }
}

/* @media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
} */

body {
  font-family: Verdana, Tahoma !important;
}

.component-container {
  padding: 26px 50px;
  margin: 0;
  display: inline-block;
  width: 100%;
  font-family: Verdana, Tahoma;
}

.field-input {
  color: black;
}
.field-input.fit-row{
  margin-top: -0.4em;
}

.has-error input {
  border: 1px solid #b71c1c !important;
}

.required-label:after {
  content: '*';
  color: #b71c1c;
}

.custom-button {
  padding: 5px 20px;
  margin-right: 10px;
}

.no-border {
  border: 0;
}

.error-message {
  color: #b71c1c;
  padding: 5px 0;
  font-size: 14px;
  font-style: italic;
}

.dialog-header {
  background-color: #5c5c64;
  font-weight: normal;
  padding: 8px 16px;
}

.dialog-header-danger {
  background-color: #b71c1c !important;
}

.dialog-title {
  color: white;
  font-size: 16pt;
}

.btn-action-dialog {
  padding: 10px 25px;
}

.dialog-body {
  padding: 48px 32px;
  font-size: 12pt;
  line-height: 1.5rem;
}
.cancel-button {
  padding: 6px 20px;
  background-color: #e9ebea;
  border-color: #e9ebea;
  color: black;
}
.save-button {
  background-color: #5c5c64;
  color: white;
  padding: 5px 20px;
}
.success-button {
  background-color: #008000;
  color: white;
  padding: 5px 20px;
}
.danger-button {
  background-color: #b71c1c;
  color: white;
  padding: 5px 20px;
}
.search-button {
  background-color: #008000;
  color: white;
  padding: 5px 20px;
  margin: 0px;
}
.btn:disabled {
  color: #212529;
}
.dialog-control .success-button {
  margin-left: 5px;
}
.dialog-control .cancel-button {
  margin-right: 5px;
}
.dialog-control {
  margin-top: 50px;
}

.mat-mdc-dialog-container {
  padding: 0 !important;
  border-radius: 10px !important;
  overflow: unset !important;
}

.mat-mdc-outlined-button.reject-btn {
  color:#b71c1c;
}
.mat-mdc-outlined-button.submit-btn {
  color:#008000;
}
.mat-mdc-outlined-button.reject-btn:disabled,
.mat-mdc-outlined-button.submit-btn:disabled {
  color:rgba(0,0,0,.26);
}

.mat-mdc-header-cell,
.mat-mdc-cell {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 14px;
}
// obs override
#obs ul {
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

#obs .tailwind ul {
  list-style-type: none;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-inline-start: inherit;
  margin-inline-end: inherit;
  padding-inline-start: inherit;
}


.loading {
  background-image: url('/../../assets/images/loader.gif');
  background-size: 150px;
  display: block;
  width: 150px;
  height: 150px;
}

.table-row-item-text {
  font-size: 12pt;
}

/* Overriding some default ngx datatable styles. */
.datatable-header {
  padding-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.datatable-header-cell-label {
  font-size: large;
  /* aligns with datatable-body-cell */
  margin-left: 25px;
}

.datatable-body-row {
  border-bottom: 2px solid #f5f5f5;
  background-color: white;
}

.datatable-body-row.active {
  background-color: #dedee4;
}

.datatable-body-row:hover {
  background-color: #fbfcfc;
}

.datatable-body-cell {
  padding: 15px 25px;
}

.padding-left-15 {
  padding-left: 15px;
}

.mat-sidenav {
  background-color: #5c5c64;
}

.nav-active,
.nav-active .nav-link {
  background: #e9ecef;
  color: black !important;
  font-weight: bold;
}

.nav-link {
  padding: 0 25px !important;
  height: 56px;
  line-height: 56px;
  width: 100%;
  font-size: 20px;
  color: white !important;
}

.nav-sub-link {
  margin-left: 20px;
}

.nav-item-container,
.nav-sub-item-container {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav-sub-item-container {
  cursor: pointer;
}

.nav-item-container:not(.nav-active):hover {
  background-color: #82818b;
}

.mat-expansion-panel,
.mat-expansion-panel-header {
  border-radius: 20px;
}
.mat-expansion-panel.no-border-radius,
.mat-expansion-panel.no-border-radius .mat-expansion-panel-header {
  border-radius: initial;
}
.mat-expansion-panel.no-border-radius .mat-expansion-panel-body{
  padding: 0 8px 16px 8px;
}

.other-client-message {
  margin-top: 30px !important;
  margin-bottom: 0px !important;
}

.mat-mdc-option.mat-active {
  background-color: rgb(172, 173, 173) !important;
}

.fas.fa-filter.fa-lg {
  margin-top: 5px;
  margin-right: 10px;
}

.page-title {
  color: #b71c1c;
}

.compapproval-form-div {
  background-color: white;
}

.tip-div {
  background-color: rgb(201, 198, 198);
}

.danger-button-link {
  color: #1c38b7;
  text-decoration: none;
}

.danger-button-link:hover,
.danger-button-link:active,
.danger-button-link:visited,
.danger-button-link:focus {
  text-decoration: none;
}

.mat-mdc-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

th.mat-mdc-header-cell {
  padding: 15px 25px !important;
}

td.mat-mdc-cell,
td.mat-mdc-footer-cell {
  padding: 15px 25px !important;
  text-align: right !important;
}
td.leftAlignCell {
  text-align: left !important;
}

.mat-mdc-snack-bar-container.mat-snack-bar-center.comp-warning {
  border: #856404 1px solid;
  border-radius: 3px;
  background: #ffeeba;
  color: #856404;
  font-weight: 600;
}
.mat-mdc-snack-bar-container.comp-warning .mat-mdc-snack-bar-action button {
  font-weight: 600;
}

.mat-mdc-snack-bar-container {
  .mdc-snackbar__label {
    color: var(--snack-bar-color) !important;
  }

  .mdc-button__label {
    color: var(--snack-bar-action-button-label-color);
  }
}

button.mdc-button {
  font-size: var(--mat-expansion-container-text-size);
}

.mat-mdc-outlined-button {
  margin-top: 10px;
  margin-left: 15px;
}

.mat-mdc-input-element:disabled{
  color: rgba(0, 0, 0, 0.7);
}

.mat-mdc-outlined-button:not(:disabled).reject-btn {
  color: var(--obs-maroon);
}

.mat-mdc-outlined-button.reset-btn {
  color: var(--obs-primary);
}

.mat-mdc-outlined-button:not(:disabled).submit-btn,
.mat-mdc-outlined-button:not(:disabled).save-btn {
  color: var(--obs-success);
}

.flex-list{
  display: flex;
  flex: 1 1 33.3em;
  flex-direction: column;
  overflow: auto;
  height: auto;
}
.component-container.flex-container{
  display: flex;
  flex-direction: column;
}

// items without children
#obs .item-level-0 > .vex-sidenav-item {
  &.vex-sidenav-item--active:not(.vex-sidenav-item--open) {
    background: var(--sidenav-item-background-active);

    .vex-sidenav-item__icon {
      color: var(--sidenav-item-icon-color-active);
    }
  }
}
// items with children
@for $i from 1 through 6 {
  #obs .item-level-#{$i} .vex-sidenav-item {
    &.vex-sidenav-item--active {
      background: var(--sidenav-item-background-active);

      .vex-sidenav-item__icon {
        color: var(--sidenav-item-icon-color-active);
      }
    }
  }

  #obs .collapsed .item-level-#{$i} .vex-sidenav-item {
    padding-inline-start: var(--sidenav-item-padding-horizontal);

    mat-icon {
      transform: scale(0.7);
    }
  }

  #obs .collapsed.open .item-level-#{$i} .vex-sidenav-item {
    padding-inline-start: calc(var(--sidenav-item-icon-size) + var(--sidenav-item-icon-gap) + var(--sidenav-item-padding-horizontal) + (var(--sidenav-item-dropdown-gap) * #{$i - 1}));

    mat-icon {
      transform: none;
    }
  }
}


#obs {
  body {
    font-family: var(--font) !important;
  }

  .vex-layout-hermes {
    --toolbar-height: 64px;
    --navigation-height: 0px;
    --toolbar-background: var(--obs-white);
    --toolbar-color: var(--text-color);

    vex-navigation {
      @apply hidden;
    }

    vex-footer .footer div {
      @apply ltr:mr-4 rtl:ml-4;
    }

    .toolbar button {
      @apply hidden;
    }

    vex-secondary-toolbar {
      @apply hidden;
    }
  }

  .mat-mdc-table .mat-mdc-cell,
  .mat-table .mat-mdc-header-cell {
    box-sizing: border-box;
    white-space: normal;
  }
  // bootstrap style override
  a {
    color: inherit;
    text-decoration: inherit;

    &:hover {
      color: inherit;
      text-decoration: inherit;
    }
  }

  label.mdc-label {
    margin-bottom: 0px;
  }
  // vex angular style override
  .vex-toolbar .mat-mdc-icon-button {
    .mat-icon {
      @apply inline items-center justify-center;
    }
  }
}



.mat-mdc-table .mat-mdc-cell,
.mat-table .mat-mdc-header-cell {
   white-space: normal !important;
}
